"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./SermonCtaCard.module.css";

export function SermonCtaCard({
  as: _Component = _Builtin.Link,
  title = "Title",
  description = "Description",
  ctaLabel = "Watch Now",

  link = {
    href: "#",
  },
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "sermon-card")}
      id={_utils.cx(
        _styles,
        "w-node-e2f6e429-6af6-9e7f-b0d2-456732832373-32832373"
      )}
      button={false}
      block="inline"
      options={link}
    >
      <_Builtin.HFlex
        className={_utils.cx(_styles, "sermon-cta-card-icon")}
        tag="div"
      >
        <_Builtin.HtmlEmbed
          className={_utils.cx(_styles, "code-embed-2")}
          value="%3Csvg%20size%3D%221.5em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3C!--!Font%20Awesome%20Pro%206.7.2%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%20(Commercial%20License)%20Copyright%202025%20Fonticons%2C%20Inc.--%3E%3Cpath%20d%3D%22M168%2072c-22.1%200-40%2017.9-40%2040l0%2032%20296%200c13.3%200%2024%2010.7%2024%2024s-10.7%2024-24%2024L24%20192c-13.3%200-24-10.7-24-24s10.7-24%2024-24l56%200%200-32c0-48.6%2039.4-88%2088-88l14.4%200C190.7%209.7%20206.2%200%20224%200l64%200c26.5%200%2048%2021.5%2048%2048s-21.5%2048-48%2048l-64%200c-17.8%200-33.3-9.7-41.6-24L168%2072zM73.5%20455.2L47.3%20224l48.3%200%2025.7%20225.8c.9%208.1%207.8%2014.2%2015.9%2014.2l173.7%200c8.1%200%2015-6.1%2015.9-14.2L352.4%20224l48.3%200L374.5%20455.2c-3.7%2032.3-31%2056.8-63.6%2056.8l-173.7%200c-32.6%200-59.9-24.4-63.6-56.8z%22%20fill%3D%22currentColor%22%20%2F%3E%3C%2Fsvg%3E"
        />
      </_Builtin.HFlex>
      <_Builtin.VFlex
        className={_utils.cx(_styles, "sermon-details")}
        tag="div"
      >
        <_Builtin.Heading
          className={_utils.cx(_styles, "heading-style-h5")}
          tag="h3"
        >
          {title}
        </_Builtin.Heading>
        <_Builtin.Block
          className={_utils.cx(_styles, "text-size-regular")}
          tag="div"
        >
          {description}
        </_Builtin.Block>
      </_Builtin.VFlex>
      <_Builtin.Block className={_utils.cx(_styles, "sermon-cta")} tag="div">
        <_Builtin.Block className={_utils.cx(_styles, "text-none")} tag="div">
          {ctaLabel}
        </_Builtin.Block>
        <_Builtin.HtmlEmbed
          className={_utils.cx(_styles, "icon-1x1-small")}
          value="%3Csvg%20width%3D%22100%25%22%20style%3D%22%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M4.16699%2010.9574H15.8337M15.8337%2010.9574L10.0003%205.12402M15.8337%2010.9574L10.0003%2016.7907%22%20stroke%3D%22%233F621A%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
        />
      </_Builtin.Block>
    </_Component>
  );
}
